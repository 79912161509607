// @ts-check
import {Button, Checkbox, FormControl, MenuItem, Select} from "@mui/material";
import _ from "lodash";
import {arrayOf, bool, func, object, string} from "prop-types";
import React, {useEffect, useState} from "react";
import {Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import {setIsSurgeonSelectorOpen} from "../../../pages/surgery_assignment/surgery_assignment_actions";
import {sortArrayOfObjectsByKey} from "../../../utils/sort_array_of_objects_by_key";
import MenuSearchTextField from "../../shared/menu_search_text_field/menu_search_text_field";
import useStyles from "../surgery_assignment_manage_dialog.styles";
import {toggleSurgeonCategory} from "../utils/helpers";
import {COLUMN_WIDTH, TABLE_WIDTH} from "./surgery_assignment_manage_table";

/** @typedef {import('react-hook-form').Control} Control */
/** @typedef {import('react-hook-form').UseFormGetValues<any>} UseFormGetValues */
/** @typedef {import('react-hook-form').FieldError} FieldError */
// /** @typedef {import('react-hook-form').FieldValues} FieldValues */

/**
 *
 * @param {Object} props
 * @param {Object} props.control
 * @param {FieldError|undefined} [props.errors]
 * @param {boolean} [props.disabled]
 * @param {string} props.name
 * @param {Function} props.onAdd
 * @param {Array<string>} props.categories
 * @param {Function} props.onReset
 * @return {React.ReactElement}
 */
export const ControlledSurgeryAssignmentSelector = ({control, errors, disabled = false, name, onAdd, categories, onReset}) => {
    const {t} = useTranslation();
    const {classes, cx} = useStyles();
    const dispatch = useDispatch();

    // State
    const [open, setOpen] = useState(false);
    const [filterText, setFilterText] = useState("");

    const hasError = !_.isEmpty(errors);

    useEffect(() => {
        dispatch(setIsSurgeonSelectorOpen(open));
        return () => {
            dispatch(setIsSurgeonSelectorOpen(false));
        };
    }, [open]);

    const handleOnOpen = () => {
        setOpen(true);
        dispatch(setIsSurgeonSelectorOpen(true));
    };
    /**
     * handle close and reset changes
     */
    const handleClose = () => {
        setOpen(false);
        dispatch(setIsSurgeonSelectorOpen(false));
        onReset();
    };

    /**
     * Handle changes whenever an extraHandler function is passed as props
     * @param {Array<SurgeonCategory>} value The list of selected values
     * @param {String} id The list of selected values
     * @param {String} category The list of selected values
     * @param {Function} onChange handler of RHF
     */
    const handleClickCheckbox = (value, id, category, onChange) => {
        const newSurgeonCategoryListState = toggleSurgeonCategory(value, {id, category});
        onChange(newSurgeonCategoryListState);
    };

    const handleAddSurgeons = () => {
        onAdd();
        setOpen(false);
        dispatch(setIsSurgeonSelectorOpen(false));
    };

    return (
        <FormControl data-testid={"surgery-assignment-add-surgeon-selector"} error={hasError} variant="standard">
            <Controller
                control={control}
                name={name}
                render={({field: {value, onChange}}) => (
                    <Select
                        classes={{
                            select: classes.select
                        }}
                        disabled={disabled}
                        disableUnderline
                        displayEmpty
                        IconComponent={() => null}
                        MenuProps={{
                            variant: "menu",
                            classes: {
                                paper: classes.menuWrapper
                            }
                        }}
                        multiple
                        open={open}
                        renderValue={() => <span className={classes.renderValue}>{t("SurgeryAssignmentManageDialog.addSurgeons")}</span>}
                        style={{width: TABLE_WIDTH - 10 + "px"}}
                        value={value}
                        variant="standard"
                        onClose={() => setOpen(false)}
                        onOpen={handleOnOpen}
                    >
                        <MenuItem data-testid="search" key="searc-text-field">
                            <MenuSearchTextField
                                placeholder={t("SurgeryAssignmentManageDialog.searchSurgeon")}
                                value={filterText}
                                onChange={setFilterText}
                            />
                        </MenuItem>
                        <MenuItem className={classes.menuRows} key="surgeon-category-rows">
                            {value
                                .sort((a, b) => sortArrayOfObjectsByKey(a, b, "name"))
                                .filter((surgeonCategory) =>
                                    filterText ? surgeonCategory.name.toLowerCase().includes(filterText.toLowerCase()) : true
                                )
                                .map((surgeonCategory) => {
                                    return (
                                        <div
                                            className={cx(classes.menu)}
                                            data-testid={`add-${surgeonCategory.id}`}
                                            key={surgeonCategory.id}
                                            role="row"
                                        >
                                            <div className={classes.surgeonName} style={{width: `${COLUMN_WIDTH.name}%`}}>
                                                {surgeonCategory.name}
                                            </div>
                                            <div className={classes.selectorCheckbox} style={{width: `${COLUMN_WIDTH.categories}%`}}>
                                                {categories.map((category) => (
                                                    <Checkbox
                                                        checked={surgeonCategory.categories.includes(category)}
                                                        className={classes.checkbox}
                                                        // @ts-ignore
                                                        inputProps={{"data-testid": `add-${surgeonCategory.id}-${category}`}}
                                                        key={`${surgeonCategory.id}-${category}`}
                                                        size="small"
                                                        onClick={() => handleClickCheckbox(value, surgeonCategory.id, category, onChange)}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    );
                                })}
                        </MenuItem>
                        <MenuItem className={classes.selectorButtonWrapper} key="button-wrapper">
                            <Button
                                className={cx(classes.button, classes.buttonWhite)}
                                color="primary"
                                data-testid="surgery-assignment-manage-dialog-cancel"
                                variant="outlined"
                                onClick={() => handleClose()}
                            >
                                {t("SurgeryAssignmentManageDialog.cancel")}
                            </Button>
                            <Button
                                className={classes.button}
                                color="primary"
                                data-testid="surgery-assignment-manage-dialog-save"
                                variant="contained"
                                onClick={handleAddSurgeons}
                            >
                                {t("SurgeryAssignmentManageDialog.apply")}
                            </Button>
                        </MenuItem>
                    </Select>
                )}
            />
        </FormControl>
    );
};

ControlledSurgeryAssignmentSelector.propTypes = {
    control: object.isRequired,
    errors: object,
    disabled: bool,
    name: string.isRequired,
    onAdd: func.isRequired,
    onReset: func.isRequired,
    categories: arrayOf(string).isRequired
};
