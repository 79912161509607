// @ts-check
import {yupResolver} from "@hookform/resolvers/yup";
import {FormHelperText} from "@mui/material";
import _ from "lodash";
import {array, bool, func, object} from "prop-types";
import React, {useContext, useEffect} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import config from "../../../../config/config.json";
import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {deleteTimeslotAction} from "../../../pages/timeslots/timeslots_actions";
import {selectSelectedDate} from "../../../pages/timeslots/timeslots_selectors";
import {selectCurrentOrganizationId, selectCurrentUserEmail} from "../../../redux/app_selectors";
import {ControlledRadioGroup, ControlledSelectorMultiple, ControlledSelectorSingle} from "../../shared/controlled_form";
import {CustomIntervalNew} from "../../shared/custom_interval_new/custom_interval_new";
import {DateRangeNew} from "../../shared/date_range_new/date_range_new";
import DeleteConfirmation from "../../shared/delete_confirmation/delete_confirmation";
import {TimeRangeNew} from "../../shared/time_range_new/time_range_new";
import {addAllOption, handleCheckboxAll, validateAndSaveTimeslot} from "../helpers";
import {useSelectedInterval} from "../hooks/useSelectedInterval";
import {TimeSlotSchema} from "./form_schema";
import useStyles from "./time_slot_dialog_form.styles";

/**
 * The form for the Timeslot dialog
 *
 * @param {object} props
 * @param {Array<{value: string, label: string}>} props.disciplines The list of disciplines ids' collections
 * @param {DefaultValuesOfTimeslot} props.defaultValues The default form values. This value are declared in the TimeslotDialog component.
 * @param {Array<{value: string, label: string}>} props.rooms The list of room ids' collections
 * @param {Array<{value: Interval, label: string}>} props.intervalList The list of possibles intervals
 * @param {TimeSlot|null} props.timeslot The data from the select timeslot (just in case a timeslot is selected)
 * @param {boolean} props.hasConflictsDates Wether the current date on the form have conflicts with other timeslots that already exist
 * @param {Function} props.handleSave Handler to try to save the data in the BE
 * @return {React.ReactElement}
 */
export const TimeslotDialogForm = ({disciplines, defaultValues, rooms, intervalList, timeslot, hasConflictsDates = false, handleSave}) => {
    const {t} = useTranslation();
    const {classes, cx} = useStyles();
    const dispatch = useDispatch();

    // redux
    const organizationId = useSelector(selectCurrentOrganizationId);
    const email = useSelector(selectCurrentUserEmail);
    const selectedDate = useSelector(selectSelectedDate);

    const {
        control,
        reset,
        resetField,
        getValues,
        setValue,
        handleSubmit,
        watch,
        setError,
        clearErrors,
        formState: {errors}
    } = useForm({
        defaultValues,
        resolver: yupResolver(TimeSlotSchema),
        mode: "onSubmit"
    });
    const hasError = !_.isEmpty(errors);
    const {getDT, diffDT, endOf, format, startOf, fromISO, fromJSDate} = useContext(DateContext);

    /** Selected form values */
    /** @type Interval */
    const selectedInterval = watch("interval");
    const selectedWeeks = watch("customWeeks");
    const selectedStartDate = watch("dateRange_start");
    const selectedEndDate = watch("dateRange_end");
    const hasNoEndDate = watch("hasNoEndDate");
    const selectedFrequency = watch("frequency");
    const selectedRadioButton = watch("radioGroup");
    const isEmergency = selectedRadioButton === "emergency";

    // effects
    useSelectedInterval({
        selectedInterval,
        resetField,
        setValue,
        clearErrors
    });

    useEffect(() => {
        // If an existing slot was opened, call the check route so that the next 5 dates are set
        if (timeslot?._id) {
            const dateFunc = {diffDT, endOf, format, fromJSDate, getDT, startOf};
            const baseData = {isEmergency, organizationId, email, id: timeslot?._id};
            validateAndSaveTimeslot({formData: defaultValues, dateFunc, baseData, handleSave, skipSave: true});
        }
    }, [timeslot, defaultValues]);

    // format data
    const radioButtons = [
        {
            label: t("TimeslotDialog.elective"),
            value: "elective"
        },
        {
            label: t("TimeslotDialog.emergency"),
            value: "emergency"
        }
    ];
    const disciplinesWithAll = addAllOption(disciplines);
    const dateRangeLabels = {
        start: ["user-defined", "daily"].includes(selectedInterval)
            ? t("EmployeeAvailabilityForm.startDate")
            : t("EmployeeAvailabilityForm.date"),
        end: t("EmployeeAvailabilityForm.endDate")
    };
    const dateRangeDisabled = {
        start: false,
        end: selectedInterval === "none" || hasNoEndDate
    };
    const extraChechboxHandler = (value) => handleCheckboxAll(value, setValue);

    const onSubmit = (data) => {
        // Check for errors that cannot be handled by RHF and save if no errors
        const dateFunc = {diffDT, endOf, format, fromJSDate, getDT, startOf};
        const baseData = {isEmergency, organizationId, email, id: timeslot?._id};
        validateAndSaveTimeslot({formData: data, baseData, dateFunc, handleSave, skipSave: false, setError});
    };
    const handleDelete = () => {
        const isExistentSlot = Boolean(timeslot?._id);
        if (!isExistentSlot) reset(defaultValues);
        if (isExistentSlot) {
            dispatch(
                deleteTimeslotAction({organizationId, id: timeslot._id}, organizationId, format(selectedDate, DATE_FORMATS.SYSTEM_DATE))
            );
        }
    };

    const handleSetFrequency = (value) => setValue("frequency", value);
    const uniqueErrors = [...new Set(Object.values(errors).map((error) => error.message))];
    return (
        <form data-testid="timeslot-dialog-form" id="timeslot" style={{width: "100%"}} onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.block}>
                <strong>{t("TimeslotDialog.slot")}</strong>
                <DeleteConfirmation
                    trigger={{
                        color: "primary",
                        label: t("TimeslotDialog.delete")
                    }}
                    onConfirm={handleDelete}
                >
                    {t("TimeslotDialog.delete_confirmation")}
                </DeleteConfirmation>
            </div>
            {/*  radio group */}
            <div className={cx(classes.formRow, classes.extraPaddingLeft)}>
                <ControlledRadioGroup
                    control={control}
                    errors={errors.radioGroup}
                    hasOwnErrorMessage={false}
                    items={radioButtons}
                    name="radioGroup"
                />
            </div>
            {hasError && (
                <FormHelperText className={classes.error} data-testid="timeslot-form-errors">
                    {uniqueErrors.map((message) => (
                        <span
                            // @ts-ignore
                            key={message}
                        >
                            {t(
                                // @ts-ignore
                                message
                            )}
                        </span>
                    ))}
                </FormHelperText>
            )}
            {hasConflictsDates && <FormHelperText className={classes.error}>{t("Form.conflictsSection")}</FormHelperText>}

            {/* disciplines selector */}
            <div className={classes.formRow}>
                {isEmergency ? (
                    <ControlledSelectorMultiple
                        classesStyle={classes.select}
                        control={control}
                        errors={errors.disciplinesSelectMultiple}
                        extraHandler={extraChechboxHandler}
                        getValues={getValues}
                        hasOwnErrorMessage={false}
                        items={disciplinesWithAll}
                        name="disciplinesSelectMultiple"
                        placeholder={t("App.disciplinesPlaceholder")}
                        reset={reset}
                        title={t("App.disciplinesLabel")}
                    />
                ) : (
                    <ControlledSelectorSingle
                        classesStyle={classes.select}
                        control={control}
                        errors={errors.disciplinesSelectSingle}
                        hasOwnErrorMessage={false}
                        items={disciplines}
                        name="disciplinesSelectSingle"
                        placeholder={t("App.disciplinePlaceholder")}
                        title={t("App.disciplineLabel")}
                    />
                )}

                {/* room ids selector */}
                <ControlledSelectorSingle
                    classesStyle={classes.select}
                    control={control}
                    errors={errors.roomId}
                    hasOwnErrorMessage={false}
                    items={rooms}
                    name="roomId"
                    placeholder={t("App.roomPlaceholder")}
                    title={t("App.roomLabel")}
                />
            </div>

            {/* date picker */}
            <div className={classes.formRow}>
                <DateRangeNew
                    control={control}
                    disabled={dateRangeDisabled}
                    errors={{dateRange_start: errors.dateRange_start, dateRange_end: errors.dateRange_end}}
                    min={startOf(fromISO(config.MIN_SLOT_DATE), "day")}
                    styles={{inputDate: classes.inputDate, switchText: classes.switchText}}
                    switchDisabled={selectedInterval === "none"}
                    title={dateRangeLabels}
                    values={hasNoEndDate ? {start: selectedStartDate, end: undefined} : {start: selectedStartDate, end: selectedEndDate}}
                />
            </div>

            {/* time picker */}
            <div className={classes.formRow}>
                <TimeRangeNew
                    control={control}
                    errors={{timeRange_start: errors.timeRange_start, timeRange_end: errors.timeRange_end}}
                    styles={{inputDate: classes.inputDate}}
                    title={t("TimeslotDialog.time")}
                />
            </div>

            {/* interval */}
            <div className={classes.formRow}>
                <ControlledSelectorSingle
                    classesStyle={classes.fullWidth}
                    control={control}
                    errors={errors.interval}
                    hasOwnErrorMessage={false}
                    items={intervalList}
                    name="interval"
                    title={t("TimeslotDialog.setInterval")}
                />
            </div>

            {/* custom-weeks */}
            {selectedInterval === "user-defined" && (
                <div className={classes.formRow}>
                    <CustomIntervalNew
                        control={control}
                        frequency={selectedFrequency}
                        isWeekendVisible
                        selectedWeeks={selectedWeeks}
                        startDate={fromJSDate(selectedStartDate)}
                        onChangeFrequency={handleSetFrequency}
                    />
                </div>
            )}
        </form>
    );
};
TimeslotDialogForm.propTypes = {
    disciplines: array.isRequired,
    defaultValues: object.isRequired,
    rooms: array.isRequired,
    intervalList: array.isRequired,
    timeslot: object,
    hasConflictsDates: bool.isRequired,
    handleSave: func.isRequired
};
