// @ts-check
import {t} from "i18next";
import _ from "lodash";

import config from "../../../../config/config.json";
/**
 * Toggle the checkbox in the SurgeryAssignmentSelector.
 *
 * @param {Array<SurgeonCategory>} list - list to be toggled
 * @param {Object} clicked
 * @param {String} clicked.id - id of clicked checkbox
 * @param {String} clicked.category - category of clicked checkbox i.e. surgeon1, mentor1 etc
 * @return {Array<SurgeonCategory>}
 */
export const toggleSurgeonCategory = (list, {id, category}) => {
    return list.map((surgeonCategory) => {
        if (surgeonCategory.id === id) {
            const modifiedSurgeonCategory = _.cloneDeep(surgeonCategory);
            if (modifiedSurgeonCategory.categories.includes(category)) {
                modifiedSurgeonCategory.categories = surgeonCategory.categories.filter((existingCategory) => existingCategory !== category);
            } else {
                modifiedSurgeonCategory.categories.push(category);
            }
            return modifiedSurgeonCategory;
        } else {
            return surgeonCategory;
        }
    });
};

/**
 * Merge modified SurgeonCategory into current SurgeonCategory.
 * Modified SurgeonCategory comes from the SurgeryAssignmentSelector that includes whole surgeons in the menu with categories checked
 * Modified SurgeonCategory is only merged if the data has at least one element in categories. (at least one checkbox is checked)
 *
 * @param {Array<SurgeonCategory>} currentData
 * @param {Array<SurgeonCategory>} modifiedData
 * @return {{mergedData: Array<SurgeonCategory>, addedIds: Array<String>}}
 */
export const mergeSurgeonData = (currentData, modifiedData) => {
    const mergedData = _.cloneDeep(currentData);
    const addedIds = [];
    for (const row of modifiedData) {
        if (row.categories.length) {
            mergedData.push(row);
            addedIds.push(row.id);
        }
    }
    return {mergedData, addedIds};
};

/**
 * format ProcedureInfo to SurgeonCategory
 * ProcedureInfo is the data fetched by the orchestrator, an element represents a surgery
 * SurgeonCategory is the data used in SurgeryAssignmentDialog, an element represents a surgery and categories he/she assigned to.
 *
 * @param {ProcedureInfo} procedure
 * @param {IdToNameCollection} allNamesObject
 * @return {Array<SurgeonCategory>}
 */
export const formatProcedureToSurgeonCategory = (procedure, allNamesObject) => {
    const surgeonsWithCategories = [];
    if (procedure?.assignedPractitioners) {
        // concatenate all ids together
        const surgeons = [];
        procedure.assignedPractitioners.forEach((assignedPractitioner) => {
            surgeons.push(...assignedPractitioner.practitioners);
        });
        // map all ids with selected categories
        [...new Set(surgeons)].forEach((surgeonId) => {
            const row = {
                id: surgeonId,
                name: allNamesObject[surgeonId] || surgeonId,
                categories: []
            };
            procedure.assignedPractitioners.forEach((assignedPractitioner) => {
                if (assignedPractitioner.practitioners.includes(surgeonId)) {
                    row.categories.push(assignedPractitioner.type);
                }
            });
            surgeonsWithCategories.push(row);
        });
    }
    return surgeonsWithCategories;
};

/**
 * set initialValue of the addingSurgeryList from the ProcedureInfo fetched by the orchestrator
 *
 * @param {ProcedureInfo} procedure
 * @param {IdToNameCollection} allNamesObject
 * @param {Array<string>} ids - surgeonIds who are assigned to the healthcare service of the chosen surgery as a surgen
 * @return {Array<SurgeonCategory>}
 */
export const formatAddingSurgeonList = (procedure, allNamesObject, ids) => {
    const addingSurgeonsList = [];
    // concatenate all ids together
    const allIdsChecked = [];
    procedure?.assignedPractitioners?.forEach((assignedPractitioner) => {
        allIdsChecked.push(...assignedPractitioner.practitioners);
    });
    const {
        TEXT_PUNCTUATION: {HYPHEN}
    } = config;
    for (const id of ids) {
        if (!allIdsChecked.includes(id)) {
            addingSurgeonsList.push({
                id,
                name: id ? allNamesObject[id] || t("App.unknown") : HYPHEN, // if id is not set, show hyphen. if id is set but the name is unknown, show unknown
                categories: []
            });
        }
    }
    return addingSurgeonsList;
};

/**
 * format SurgeonCategory into params for the patch call
 * @param {String} procedureCode
 * @param {Array<SurgeonCategory>} surgeonCategories
 * @param {Array<String>} categories
 * @return {{procedureCode: String, assignedPractitioners: Array<{type: String, practitioners: Array<String>}>, allDisplayedPractitioners: Array<String>}}
 */
export const formatSurgeonCategoryToProcedure = (procedureCode, surgeonCategories, categories) => {
    const assignedPractitioners = categories.map((category) => ({type: category, practitioners: []}));

    for (const assignedPractitioner of assignedPractitioners) {
        for (const surgeonCategory of surgeonCategories) {
            if (surgeonCategory.categories.includes(assignedPractitioner.type)) {
                assignedPractitioner.practitioners.push(surgeonCategory.id);
            }
        }
    }
    const allDisplayedPractitioners = surgeonCategories.map((surgeonCategory) => surgeonCategory.id);
    return {procedureCode, assignedPractitioners, allDisplayedPractitioners};
};
